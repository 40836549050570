// src/components/Input.js
import React from 'react';

function Input({ userInput, onInputChange }) {
  const handleChange = (e) => {
    onInputChange(e.target.value);
  };

  return (
    <input
      className="form-control"
      type="text"
      value={userInput}
      onChange={handleChange}
    />
  );
}

export default Input;