// src/data/wordLists.js
export const wordsLevel1 = [
    "apple", "banana", "cat", "dog", "elephant", 
    "fish", "grape", "hat", "ice", "juice",
    "kite", "lemon", "monkey", "nose", "orange", 
    "pen", "queen", "red", "sun", "tree"
  ];
  
  export const wordsLevel2 = [
    "book", "chair", "dance", "elephant", "frog", 
    "guitar", "happy", "island", "jungle", "kite", 
    "lemonade", "mountain", "night", "ocean", "pizza", 
    "quiet", "river", "snow", "tiger", "umbrella"
  ];
  
  export const wordsLevel3 = [
    "adventure", "beautiful", "celebrate", "discover", "enormous", 
    "festival", "glimpse", "hero", "island", "jewel", 
    "kingdom", "laughter", "mystery", "nature", "ocean", 
    "puzzle", "quest", "rescue", "treasure", "victory"
  ];
  
  export const wordsLevel4 = [
    "ability", "benefit", "challenge", "dedicate", "effort", 
    "focus", "growth", "habit", "innovation", "journey", 
    "knowledge", "leadership", "motivation", "network", "opportunity", 
    "progress", "quality", "responsibility", "success", "transformation"
  ];
  
  export const wordsLevel5 = [
    "academic", "boundary", "complexity", "discovery", "evaluate", 
    "fundamental", "hypothesis", "implementation", "justification", "knowledgeable", 
    "logic", "methodology", "nuance", "observation", "phenomenon", 
    "quantify", "research", "sophisticated", "theoretical", "validity"
  ];
  
  export const wordsLevel6 = [
    "abstraction", "binary", "catalyst", "dichotomy", "ephemeral", 
    "fractal", "granular", "heuristic", "inference", "juxtaposition", 
    "kaleidoscope", "lexicon", "metaphor", "neologism", "ontological", 
    "paradigm", "quintessential", "rhetoric", "syntax", "ubiquitous"
  ];
  
  export const wordsLevel7 = [
    "acquiesce", "belligerent", "circumlocution", "deleterious", "epitome", 
    "facetious", "gregarious", "heterogeneous", "impecunious", "juxtapose", 
    "ken", "laconic", "magnanimous", "nebulous", "obfuscate", 
    "paradigm", "quandary", "recalcitrant", "sagacious", "tantamount"
  ];
  
  export const wordsLevel8 = [
    "antediluvian", "blandishment", "cogent", "diaphanous", "effulgent", 
    "fatuous", "garrulous", "heterogeneous", "impetuous", "jejune", 
    "kismet", "lachrymose", "mellifluous", "nefarious", "obstreperous", 
    "paragon", "quixotic", "refulgent", "sycophant", "truculent"
  ];
  
  export const wordsLevel9 = [
    "abstruse", "bellicose", "cachinnate", "delectation", "effervescent", 
    "felicitous", "gossamer", "histrionic", "incandescent", "juxtaposition", 
    "kaleidoscopic", "lambent", "mendacious", "nebulous", "obstreperous", 
    "perfidious", "quizzical", "recalcitrant", "sacrosanct", "taciturn"
  ];
  
  export const wordsLevel10 = [
    "antediluvian", "brusque", "cynosure", "deleterious", "effulgent", 
    "fastidious", "gregarious", "heuristic", "impecunious", "juxtapose", 
    "kaleidoscope", "lambent", "mellifluous", "nefarious", "obstreperous", 
    "pugnacious", "quintessential", "recalcitrant", "sycophant", "ubiquitous"
  ];