import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ResultPage from './pages/ResultPage';
import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import './App.css'; // スタイルを適用するためにCSSファイルをインポート

function App() {
  const { isAuthenticated } = useAuth0();

  return (
    <Router>
      <div>
        <nav className="navbar">
          <div className="navbar-brand">タイピングゲーム</div>
          <div className="navbar-buttons">
            {isAuthenticated ? <LogoutButton /> : <LoginButton />}
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/result" element={<ResultPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;