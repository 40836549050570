import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ResultPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInputHistory, correctWordHistory, score } = location.state || {
    userInputHistory: [],
    correctWordHistory: [],
    score: 0,
  };

  return (
    <div className="container">
      <h2>結果発表</h2>
      <p>スコア: {score}</p>
      <table>
        <thead>
          <tr>
            <th>入力した単語</th>
            <th>正しい単語</th>
          </tr>
        </thead>
        <tbody>
          {userInputHistory.map((userInput, index) => (
            <tr key={index}>
              <td>{userInput}</td>
              <td>{correctWordHistory[index]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => navigate('/')}>ホームに戻る</button>
    </div>
  );
}

export default ResultPage;