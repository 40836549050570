// src/components/QuestionSelector.js
import React from 'react';

function QuestionSelector({ setQuestionCount, setLevel }) {
  return (
    <div className="mb-3">
      <label className="form-label">
        問題数:
        <select className="form-select" onChange={(e) => setQuestionCount(parseInt(e.target.value))}>
          {[...Array(10).keys()].map((i) => (
            <option key={i} value={i + 1}>
              {i + 1}問
            </option>
          ))}
        </select>
      </label>
      <label className="form-label">
        レベル:
        <select className="form-select" onChange={(e) => setLevel(parseInt(e.target.value))}>
          {[...Array(10).keys()].map((i) => (
            <option key={i} value={i + 1}>
              レベル {i + 1}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
}

export default QuestionSelector;