import React, { useState, useEffect, useCallback } from 'react';
import CurrentWord from '../components/CurrentWord';
import Input from '../components/Input';
import QuestionSelector from '../components/QuestionSelector';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode'; // インポートの修正
import { 
  wordsLevel1, 
  wordsLevel2, 
  wordsLevel3, 
  wordsLevel4, 
  wordsLevel5, 
  wordsLevel6, 
  wordsLevel7, 
  wordsLevel8, 
  wordsLevel9, 
  wordsLevel10 
} from '../data/wordLists';

function HomePage() {
  const [currentWord, setCurrentWord] = useState('');
  const [userInput, setUserInput] = useState('');
  const [score, setScore] = useState(0);
  const [questionCount, setQuestionCount] = useState(5);
  const [questionsRemaining, setQuestionsRemaining] = useState(null); // ゲーム開始前はnull
  const [userInputHistory, setUserInputHistory] = useState([]);
  const [correctWordHistory, setCorrectWordHistory] = useState([]);
  const [usedWords, setUsedWords] = useState([]);
  const [level, setLevel] = useState(1);
  const [decodedToken, setDecodedToken] = useState(null); // idTokenを削除
  const navigate = useNavigate();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  const getWordsForLevel = useCallback(() => {
    switch (level) {
      case 1:
        return wordsLevel1;
      case 2:
        return wordsLevel2;
      case 3:
        return wordsLevel3;
      case 4:
        return wordsLevel4;
      case 5:
        return wordsLevel5;
      case 6:
        return wordsLevel6;
      case 7:
        return wordsLevel7;
      case 8:
        return wordsLevel8;
      case 9:
        return wordsLevel9;
      case 10:
        return wordsLevel10;
      default:
        return wordsLevel1;
    }
  }, [level]);

  const setNewWord = useCallback(() => {
    const allWords = getWordsForLevel();
    const availableWords = allWords.filter(word => !usedWords.includes(word));

    if (availableWords.length === 0) {
      // すべての単語が出題済みの場合、出題済みリストをリセット
      setUsedWords([]);
      const newWord = allWords[Math.floor(Math.random() * allWords.length)];
      setCurrentWord(newWord);
      setUsedWords([newWord]);
    } else {
      const randomIndex = Math.floor(Math.random() * availableWords.length);
      const newWord = availableWords[randomIndex];
      setCurrentWord(newWord);
      setUsedWords([...usedWords, newWord]);
    }
  }, [getWordsForLevel, usedWords]);

  useEffect(() => {
    if (questionsRemaining === 0 && questionsRemaining !== null) {
      navigate('/result', { state: { userInputHistory, correctWordHistory, score } });
    }
  }, [questionsRemaining, navigate, userInputHistory, correctWordHistory, score]);

  const handleInputChange = (input) => {
    setUserInput(input);
    if (input === currentWord) {
      setScore(score + 1);
      setUserInputHistory(prev => [...prev, input]);
      setCorrectWordHistory(prev => [...prev, currentWord]);
      setQuestionsRemaining(prev => prev - 1);
      setUserInput('');
      if (questionsRemaining > 1) {
        setNewWord();
      }
    } else if (input.length === currentWord.length && input !== currentWord) {
      setUserInputHistory(prev => [...prev, input]);
      setCorrectWordHistory(prev => [...prev, currentWord]);
      setQuestionsRemaining(prev => prev - 1);
      setUserInput('');
      if (questionsRemaining > 1) {
        setNewWord();
      }
    }
  };

  const startGame = () => {
    setScore(0);
    setUserInputHistory([]);
    setCorrectWordHistory([]);
    setQuestionsRemaining(questionCount);
    setUsedWords([]);
    setNewWord(); // 新しいゲームを開始するときに最初の単語を設定
  };

  useEffect(() => {
    const fetchIdToken = async () => {
      if (isAuthenticated) {
        const idTokenClaims = await getIdTokenClaims();
        setDecodedToken(jwtDecode(idTokenClaims.__raw)); // idTokenの代わりに直接設定
      }
    };

    fetchIdToken();
  }, [isAuthenticated, getIdTokenClaims]);

  return (
    <div className="container">
      <h1 className="my-4">タイピングゲーム</h1>
      {isAuthenticated && <p>ログイン済みユーザーのメッセージ</p>}
      <QuestionSelector setQuestionCount={setQuestionCount} setLevel={setLevel} />
      <button className="start-button my-3" onClick={startGame}>スタート</button>
      {questionsRemaining !== null && questionsRemaining > 0 ? (
        <div>
          <CurrentWord word={currentWord} />
          <Input userInput={userInput} onInputChange={handleInputChange} />
          <p>スコア: {score}</p>
          <p>残りの問題数: {questionsRemaining}</p>
        </div>
      ) : (
        <p>ゲームを開始してください。</p>
      )}
      {isAuthenticated && (
        <div>
          <h2>デコードされたIDトークン</h2>
          <pre style={{ textAlign: 'left', backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '5px' }}>
            {JSON.stringify(decodedToken, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}

export default HomePage;