// src/components/CurrentWord.js
import React from 'react';

function CurrentWord({ word }) {
  return (
    <div className="alert alert-primary">
      <h2>{word}</h2>
    </div>
  );
}

export default CurrentWord;